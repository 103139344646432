import { Component, Host, Prop, Fragment, h } from '@stencil/core';

@Component({
  tag: 'x-header-badge',
  styleUrl: 'x-header-badge.sass',
})
export class XHeaderBadge {
  @Prop() container: boolean;
  @Prop() group: boolean;
  @Prop() item: boolean;
  @Prop() new: boolean;
  @Prop() label: string;
  @Prop() change: string;
  @Prop() icon: string;
  @Prop() href: string;
  @Prop() color: 'grey' | 'green' | 'yellow';

  render() {
    if (this.container) {
      <Host>
        <slot></slot>
      </Host>;
    } else if (this.group) {
      return (
        <Host class="x-header-badge">
          {!!this.label && <div class="x-header-badge__label">{this.label}</div>}
          <div class="x-header-badge__list">
            <slot></slot>
          </div>
        </Host>
      );
    } else if (this.item) {
      return (
        <Host class="x-header-badge__item">
          {!!this.href && <a href={this.href}></a>}
          {!!this.icon && (
            <figure>
              <x-icon glyph={this.icon} size="16"></x-icon>
            </figure>
          )}
          {this.label}
          {!!this.change && (
            <Fragment>
              {!!this.label && <Fragment>&nbsp;&nbsp;</Fragment>}
              <b>{this.change}</b>
            </Fragment>
          )}
        </Host>
      );
    } else {
      return null;
    }
  }
}
