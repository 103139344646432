x-header-badge[container]
  display: contents

.x-header-badge
  +preset(3)
  display: flex
  &__label
    color: var(--on-primary-dark)
    padding: 4px 8px 0 0
  &__list
    display: flex
    flex-wrap: wrap
    margin: -4px
  &__item
    display: inline-flex
    white-space: nowrap
    position: relative
    height: 24px
    padding: 4px 9px 0
    margin: 4px
    box-sizing: border-box
    border-radius: var(--base-radius)
    a
      display: block
      position: absolute
      top: 0
      left: 0
      right: 0
      bottom: 0
      z-index: 2
    figure
      margin: 0 6px 0 -1px
      flex: 0 0 auto
    &[color="grey"]
      background-color: var(--button-static-grey)
      color: var(--on-button-dark)
    &[color="green"]
      background-color: var(--button-static-green)
      color: var(--on-button-white)
    &[color="yellow"]
      background-color: var(--button-yellow)
      color: var(--on-button-white)
